import Vue from 'vue'
import Router from 'vue-router'
import HomeView from './views/HomeView.vue'

Vue.use(Router)

export default new Router({
	base: '/',
	mode: 'history',
	linkActiveClass : 'routerLink--active',
	linkExactActiveClass : 'routerLink--exactActive',
	routes: [
		{
			path: '/',
			name: 'HomeView',
			component: HomeView
		},
		{
			path: '/:slug',
			name: 'PageView',
			component: HomeView
		},
		/*
		*/
		{
			path: "*", 
			name: 'NotFoundView',
			component: () =>
				import ( /* webpackChunkName: "NotFoundView" */ './views/NotFoundView.vue')			
		},
	]
})
