<template>
	<div class="homeView">
		
		<strong>HomeView.vue</strong><hr />
		<br />

		Fetched {{pages.length}} pages from "/wp-json/wp/v2/pages": <br />
		<br />
		
		<ul>
			<li v-for="page in pages" :key="page.id">
				<router-link :to="getLinkWithoutHostname(page.link)">
					{{page.title.rendered}}
				</router-link> 
			</li>
		</ul>
		
		<pre data-name="pages">{{pages}}</pre>
		
	</div>
</template>

<script>
	// @ is an alias to /src	
	import MhImage from '@/components/MhImage/MhImage.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import { EventBus } from '@/event-bus.js' // TODO: remove this

	export default {
		name: 'HomeView',
		components: {
			MhImage,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {
		},
		data() {
			return {
				pages: [],
			}
		},
		computed: {},
		methods: {
			getLinkWithoutHostname( url ){
				let _url = new URL( url )
				return _url.pathname
			},
		},
		mounted() {
			// send loading to MhView to show the spinner
			EventBus.$emit('stateChanged', 'loading')
			
			// fetch pages as example
			this.restHandler__fetch({
				action : 'GET',	
				route : '/wp-json/wp/v2/pages',
				params : {},
				callback : (response) => {
					this.pages = response.data
					
					// send loaded to MhView to hide the spinner again
					EventBus.$emit('stateChanged', 'loaded')	
				},
			})	
		},
	}
</script>

<style lang="less">
	@import "../less/includes/grid.include.less";

	.HomeView {}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
