<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<DbWhiteWrapper></DbWhiteWrapper>
	
	2019-10-02	init

-->

<template>
	<div class="DbWhiteWrapper" :class="elmClasses">
		<div class="DbWhiteWrapper__inner">
			<h1 class="DbWhiteWrapper__logo flex">
				<router-link class="flex--grow" :to="{ name: 'HomeView' }">Doppelblick</router-link>
			</h1>
			<DbCover></DbCover>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import DbCover from '@/components/DbCover.vue'

	export default {
		name: 'DbWhiteWrapper',
		components: {
			DbCover,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";
	
	.DbWhiteWrapper {
		position: relative;
		background-color: @swatches[bgWhite];
		
		&__logo {
			[showBorders2] & {
				background-color: fade( red, 10 );				
			}
			position: absolute;
			top: 2.5vw; left: 50%;
			transform: translateX( -50% );
			width: 15vw; height: 5vw;
			overflow: hidden;			
			text-indent: -100em;
			z-index: 10;
			
			background-image: url(../assets/images/logo.png);
			background-size: contain;
			background-position: center top;
			background-repeat: no-repeat;
			
			@media @mediaQuery__md {
				top: 10vw;
				width: 50vw;
				height: 10vw;
			}
			@media @mediaQuery__dt {
				
			}
		}
	}
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
