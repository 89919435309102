export default {
	"environments" : {
		"dev" : { 										// the name of the environment
			"hostname" : "localhost", 					// the hostname the app is served from, this detects the environment
			"restBase" : "http://doppelblick.local:8080", // the url to the wordpress restApi
		},
		"dev2" : { 							// the name of the environment
			"hostname" : "192.168", 					// the hostname the app is served from, this detects the environment
			"restBase" : "http://doppelblick.local:8080", // the url to the wordpress restApi
		},
		"dev3" : { 							// the name of the environment
			"hostname" : "doppelblick.local", 					// the hostname the app is served from, this detects the environment
			"restBase" : "http://doppelblick.local:8080", // the url to the wordpress restApi
		},
		"stage" : { 							// the name of the environment
			"hostname" : "dev.doppelblick.org", 					// the hostname the app is served from, this detects the environment
			"restBase" : "//dev.doppelblick.org", // the url to the wordpress restApi
		},
		"live" : { 							// the name of the environment
			"hostname" : "doppelblick.org", 					// the hostname the app is served from, this detects the environment
			"restBase" : "//doppelblick.org", // the url to the wordpress restApi
		},
	}
}
