<template>
	<div class="app minHeight minHeight--100vh grid grid--appTemplate" :class="elmClasses">
		
		<DbWhiteWrapper></DbWhiteWrapper>
		<DbGreenWrapper></DbGreenWrapper>
		 
		<MhDelegateLinks></MhDelegateLinks>
		<MhDevInfos :showOnHosts="['localhost', 'wp-kickstart-vue:8080']"></MhDevInfos>
		
	</div>
</template>

<script>
	// @ is an alias to /src 
	import { EventBus } from '@/event-bus.js'	
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import resize from 'vue-resize-directive' 
	import MhDevInfos from '@/components/MhDevInfos/MhDevInfos.vue'
	import MhDelegateLinks from '@/components/MhDelegateLinks/MhDelegateLinks.vue'
	import DbWhiteWrapper from '@/components/DbWhiteWrapper.vue'
	import DbGreenWrapper from '@/components/DbGreenWrapper.vue'

	export default {
		name: 'App',
		components: {
			MhDevInfos,
			MhDelegateLinks,
			DbWhiteWrapper,
			DbGreenWrapper,
		},
		directives: {
			resize,
		},		
		mixins: [ RestHandler ],		
		data() {
			return {
				siteName : '',
				acfOptions : null,
				mainPosts : null,
				footerPosts : null,
				posts : null,
				
				// for vue-meta
				title : '',
				titleTemplate : (titleChunk) => {
					return titleChunk ? `${titleChunk} — ` + this.siteName : this.siteName
				},
			}
		},
		metaInfo() {
			return {
				title: this.title,
				titleTemplate: this.titleTemplate,
				meta: [],
				link: [],
			}
		},
		watch: {
			'post': { // watch active post and set title for vue-meta
				handler: function( to, from ) {
					if( to && to.title ){
						this.title = to.title.rendered
					}else{
						this.title = null						
					}
				},
				deep: true,
			},
		},
		computed: {
			post(){
				const slug = this.$route.params.slug ? this.$route.params.slug : false
				
				if( slug ){
					return this._.filter( this.posts, { slug } )[0]
				}else{
					return false
				}
			},
			elmClasses(){
				const classes = []
				return classes
			},
		},
		methods: {
			getHostname(){	// for hidding the devInfos on some environments
				return window.location.hostname
			},	
		},
		mounted() {
			// fetch site infos
			this.restHandler__fetch({
				action : 'GET',	
				route : '/wp-json',
				params : {},
				callback : (response) => {
					this.siteName = response.data.name
					this.$meta().refresh() // muss manuell getriggert werden, damit das setzen nach dem fetch klappt
				},
			})
					
			// fetch acfOptions + all related posts
			this.restHandler__fetch({
				action : 'GET',	
				route : '/wp-json/mh/v1/acfOptions',
				params : {},
				callback : (response) => {
					// set acfOptions
					this.acfOptions = response.data.result
					
					// get the ids for the mainPosts
					let mainPostIds = []					
					mainPostIds.push( this.acfOptions.firstLink )
					mainPostIds.push( this.acfOptions.secondLink )
					mainPostIds.push( this.acfOptions.thirdLink )
					
					// get the ids from the footerLinks
					let footerPostIds = []
					this._.forEach( this.acfOptions.internalFooterLinks, ( link ) => {		
						console.log(link.post);				
						footerPostIds.push( link.post.ID )
					})
					
					console.log('footerPostIds', footerPostIds);				
					
					// remove falsy and null values
					mainPostIds = this._.filter( mainPostIds ) 
					footerPostIds = this._.filter( footerPostIds )
					
					this.posts = []
					this.mainPosts = []
					this.footerPosts = []
					
					// fetch the mainPostIds by postIds					
					this.restHandler__fetch({
						action : 'GET',	
						route : '/wp-json/mh/v1/posts',
						params : {
							postIds : mainPostIds,
						},
						callback : (response) => {
							const results  = response.data.result
							
							this._.forEach( results, (result) => {
								this.mainPosts.push( result )
								this.posts.push( result )
							})
						},
					})	
					// fetch the footerPostIds by postIds					
					this.restHandler__fetch({
						action : 'GET',	
						route : '/wp-json/mh/v1/posts',
						params : {
							postIds : footerPostIds,
						},
						callback : (response) => {
							const results  = response.data.result
							
							this._.forEach( results, (result) => {
								this.footerPosts.push( result )
								this.posts.push( result )
							})
							
							// short timeout is needed, dont know why
							setTimeout( () => {
								const pageSlug = this.$route.params.slug
								const selector = '#' + pageSlug + ' .DbRow__scrollToHelper'
								const scrollToElm = document.querySelector( selector )
								
								console.log('selector: ', selector);
								console.log('pageSlug: ', pageSlug);
								console.log('scrollToElm: ', scrollToElm);
								
								if( scrollToElm ){
									setTimeout( () => {
										scrollToElm.scrollIntoView({block: "start", behavior: "smooth"})
									}, 200)
								}
							}, 50)
						},
					})	
					
				},
			})		
		}
	}
</script>

<style lang="less"> 
	@import "less/includes/normalize.include.less";
	@import "less/vars.less";
	@import "less/mixins.less";
	@import "less/atoms.less";
	@import "less/fonts.less";
	@import "less/includes/grid.include.less"; 

	body {
		.font; 
		.font--sans; 
		.font--sizeDefault;
		.font--smooth;
	}
	strong, b {
		.font--bold;
	}
	button {
		cursor: pointer;
	}
	h1, h2, h3, h4, h5 {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		margin: 0;
	}
	a {
		cursor: pointer;
		color: blue;
		color: inherit;
		text-decoration: none;
	}
	pre {
		@color: black;
		
		position: relative;
		padding: 0.5em;
		width: 100%;
		min-height: 2em;
		
		margin-bottom: 0.5em;
		&:first-of-type { margin-top: 1em; }
		&:last-of-type { margin-bottom: 1em; }
		
		//color: fade(@color, 65);
		tab-size: 4;
		white-space: pre;
		
		outline: 1px solid fade(@color, 25);
		background-color: fade(@color, 5);
		overflow: auto;		
		//display: none;
		
		// label
		&[name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(name);
			font-family: inherit;
		}		
		
		&:first-child {
			margin-top: 0em;
		}
	}
	hr {
		border: none;
		border-top: 1px solid;
		margin: 0.5em 0;
		opacity: 0.25;
	}

	.app {}
	.app__bodyInner {
		background-color: fade( white, 90 );
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
