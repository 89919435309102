<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<DbGreenWrapper></DbGreenWrapper>
	
	2019-10-02	init

-->

<template>
	<div class="DbGreenWrapper flex" :class="elmClasses">
		<nav class="DbGreenWrapper__inner flex flex--grow color color--yellow">
			
			<!-- mobile -->
			<template v-if="$root.isMaxTabletDevice">
				<div class="DbGreenWrapper__layout DbGreenWrapper__layout--rows flex flex--grow flex--column">
					<div class="">
						<DbRow 
							v-for="(post, index) in app.mainPosts" 
							:key="index" 
							:post="post"
							headerFontSize="big"
						></DbRow>					
						
						<div class="DbGreenWrapper__expander border border--top border--strong XXXflex--grow"></div>
					</div>
					
					<div class="">
						<br /><br /><br /><br />
						<br /><br /><br /><br />
						
						<DbRow 
							v-for="(post, index2) in app.footerPosts" 
							:key="'x' + index2" 
							:post="post"
							headerFontSize="default"
						></DbRow>										

						<ul class="font font--bold font--sizeDefault reset reset--list" v-if="app.acfOptions">
							<li class="border border--top border--strong " v-for="(link, index) in app.acfOptions.externalFooterLinks" :key="'i'+index">
								<a class="block vSpace vSpace--rowPageHeader" :href="link.externalLink" target="_blank">{{link.title}}</a>
							</li>
						</ul>
						
						<div class="border border--top border--strong"></div>
					</div>
				</div>
			</template>
			
			<!-- desktop -->
			<template v-else>
				<div class="DbGreenWrapper__layout DbGreenWrapper__layout--cards">
					
					<!-- Cards -->
					<div class="DbGreenWrapper__cards grid grid--greenCells">
						<!-- Was -->
						<div class="DbCard flex">
							<h2 class="DbCard__inner flex flex--grow" v-if="app.mainPosts && app.mainPosts[0]">
								<router-link 
									class="DbCard__link font font--sizeHuge" 
									:to="'/' + app.mainPosts[0].slug + '/'" 
									v-html="app.mainPosts[0].acf.postTitle"
								></router-link>
							</h2>
						</div>
						<!-- Wo -->
						<div class="DbCard flex">
							<h2 class="DbCard__inner flex flex--grow" v-if="app.mainPosts && app.mainPosts[1]">
								<router-link 
									class="DbCard__link font font--sizeHuge" 
									:to="'/' + app.mainPosts[1].slug + '/'" 
									v-html="app.mainPosts[1].acf.postTitle"
								></router-link>
							</h2>
						</div>
						<!-- Wer -->
						<div class="DbCard flex">
							<h2 class="DbCard__inner flex flex--grow" v-if="app.mainPosts && app.mainPosts[2]">
								<router-link 
									class="DbCard__link font font--sizeHuge" 
									:to="'/' + app.mainPosts[2].slug + '/'" 
									v-html="app.mainPosts[2].acf.postTitle"
								></router-link>
							</h2>
						</div>
						<!-- footerLinks -->						
						<div class="DbCard flex flex--bottom">
							<ul class="DbCard__footerNavList font font--sizeDefault reset reset--list" v-if="app.acfOptions">
								<li class="DbCard__footerNavListItem" v-for="(link, index) in app.acfOptions.externalFooterLinks" :key="'i'+index">
									<a :href="link.externalLink" target="_blank">{{link.title}}</a>
								</li>
								<li class="DbCard__footerNavListItem" v-for="(post, index) in app.footerPosts" :key="index">
									<router-link :to="'/' + post.slug + '/'">{{post.title.rendered}}</router-link>
								</li>
							</ul>
						</div>	
						
						<div class="stroke stroke--h"></div>
						<div class="stroke stroke--v"></div>
					</div>
					
					<!-- Content -->
					<div class="DbPageWrapper" v-if="$route.name == 'PageView'">
						<div class="DbPageWrapper__inner" v-if="app.post">
							<h2 class="DbPageWrapper__header font font--sizeHuge border border--bottom border--strong flex vSpace vSpace--cardsPageHeader">
								<div class="DbPageWrapper__postTitle flex--grow" v-html="app.post.acf.postTitle"></div>
								<router-link class="DbPageWrapper__closeBtn" :to="{ name: 'HomeView' }">close</router-link>
							</h2>
							<div class="DbPageWrapper__body vSpace vSpace--cardsPageBody">
								<div	
									v-for="(contentModule, j) in app.post.acf.contentModules" 
									:key="j" 
									v-bind="contentModule" 
									:is="contentModule.acf_fc_layout"
								></div>
							</div>
						</div>
					</div>
				
				</div>
			</template>		
			
		</nav>
	</div>
</template>

<script>
	// @ is an alias to /src
	import { EventBus } from '@/event-bus.js'
	import DbRow from '@/components/DbRow.vue'
	import DbText from '@/components/DbText.vue'
	import DbImage from '@/components/DbImage.vue'

	export default {
		name: 'DbGreenWrapper',
		components: {
			DbRow,
			DbText,
			DbImage,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {}
		},
		watch: {
			'app.mainPosts': {
				handler: function( to, from ) {
					//console.log( to )
				},
				deep: true,
			},
			'$route.params.slug': { // scroll to content container
				handler: function( to, from ) {
					if( this.$root.isMaxTabletDevice ){
						const pageSlug = this.$route.params.slug
						const selector = '#' + pageSlug + ' .DbRow__scrollToHelper'
						const scrollToElm = document.querySelector( selector )
						
						console.log('selector: ', selector);
						console.log('pageSlug: ', pageSlug);
						console.log('scrollToElm: ', scrollToElm);
						
						if( scrollToElm ){
							setTimeout( () => {
								scrollToElm.scrollIntoView({block: "start", behavior: "smooth"})
							}, 200)
						}
					}
				},
				deep: true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	//@import "../less/mixins.less";
	//@import (reference) "../less/atoms.less";
	
	
	.DbGreenWrapper {
		@strokeWidth : 3px;
		@padding : 1.5rem;
		
		position: relative;
		background-color: @swatches[green];
		
		&__inner {}
		
		&__layout--cards {
			width: 100%;
			
			.DbGreenWrapper__cards {
				//outline: 1px solid red;
				
				position: relative;
				padding: @padding;
				width: 100%;
				height: 100%;
			}
			
			/*
			.DbGreenWrapper__cardList {
				transform: translateY( 0.3em );
			}
			.DbGreenWrapper__cardListItem {}
			.DbGreenWrapper__cardListItemLink {
				display: block;
				padding: 0.1em 0;
			}
			*/
		
			.stroke {
				//display: none;
				position: absolute;
				border: @strokeWidth/2 solid @swatches[yellow];
			}
			.stroke--h {
				top: calc( 50% - @strokeWidth/2 );
				left: @padding; right: @padding;
			}
			.stroke--v {
				left: calc( 50% - @strokeWidth/2 );
				top: @padding; bottom: @padding;
			}
		
			.DbPageWrapper {
				//display: none;
				
				position: absolute;
				top: 0; left: 0; 
				width: 100%; height: 100%;
				//background-color: fade( @swatches[green], 75 );
				background-color: fade( @swatches[green], 100 );
				
			}
			.DbPageWrapper__closeBtn {
				//outline: 1px solid red;
				
				position: relative;
				color: transparent;
				
				&::before, &::after {
					position: absolute;
					top: 0.5em; right: 0;
					height: @strokeWidth*1.5;
					width: 1em;
					background-color: @swatches[yellow];
					content: "";
				}
				&::before {
					transform: rotate( 45deg );
				}
				&::after {
					transform: rotate( -45deg );
				}
			}
			
			.DbPageWrapper__inner {
				padding: @padding;
				overflow-y: auto;
				overflow-x: hidden;				
				height: 100%;
			}
		}
		&__layout--rows {				
			justify-content: space-between;
		}
		
		@media @mediaQuery__md {
			padding: 1.2rem 1rem;
		}
		
		//-webkit-text-stroke: 0.02em red;
	}
	
	.contentModule {
		margin-bottom: 1.5rem;
		max-width: 100%;
	}
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
