<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<DbImage
			:image="{}"
		></DbImage>
	
	2019-10-11	init

-->

<template>
	<div class="DbImage contentModule" :class="elmClasses" v-if="image">
		<MhImage :imageObject="image" mode="img" backgroundPosition="center"></MhImage>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'DbImage',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			image: [Object, Boolean],
		},
		data() {
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	//@import "../less/mixins.less";
	//@import (reference) "../less/atoms.less";
	
	.DbImage {
		background-color: @swatches[yellow];
	}
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
