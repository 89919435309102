<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<DbRow></DbRow>
	
	2019-10-08	init

-->

<template>
	<article class="DbRow" :id="post.slug" :class="elmClasses">
		<div class="DbRow__scrollToHelper"></div>
		<header class="DbRow__header border border--top border--strong flex flex--top font font--bold vSpace vSpace--rowPageHeader" :class="headerClasses">
			<div class="" v-html="post.acf.postTitle"></div>
			<h2 class="font font--sizeBig">
				<span class="DbRow__toggleIcon clip clip--triangleBottom" 
					:class="{'DbRow__toggleIcon--isActive' : rowIsActive}"
				><br /></span>
			</h2>
			<button class="DbRow__rowToggleBtn" role="button" @click="toggleRow()">Toggle</button>									
		</header>
		<div class="DbRow__body " v-show-slide="rowIsActive">
			<div class="flex flex--column vSpace vSpace--rowPageBody font font--sizeDefault border border--top">
				<div	
					v-for="(contentModule, j) in post.acf.contentModules" 
					:key="j" 
					v-bind="contentModule" 
					:is="contentModule.acf_fc_layout"
				></div>
			</div>
		</div>
	</article>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import DbText from '@/components/DbText.vue'
	import DbImage from '@/components/DbImage.vue'

	export default {
		name: 'DbRow',
		components: {
			DbText,
			DbImage,
		},
		mixins: [],
		props: {
			post: [Object, Boolean],
			headerFontSize: [String, Boolean],
		},
		data() {
			return {
				rowIsActive : false,
			}
		},
		watch: {
			'$route.params.slug': { // watch route
				handler: function( to, from ) {
					if( this.post && to == this.post.slug ){
						this.rowIsActive = true
					}
				},
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []
				
				return classes
			},
			headerClasses(){
				let classes = []
				
				if( this.headerFontSize ) classes.push( 'font--size' + this._.upperFirst( this.headerFontSize ) )
				
				return classes
			},
		},
		methods: {
			toggleRow(){ // changes route path
				
				if( !this.rowIsActive ){
					this.$router.push('/' + this.post.slug + '/')					
				}else{
					this.$router.push('/')	
				}
				
				this.rowIsActive = !this.rowIsActive
			},
		},
		created() {},
		mounted() {
			// unfold onload
			if( this.post && this.app.post && this.post.slug == this.app.post.slug ) this.rowIsActive = true
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	//@import (reference) "../less/atoms.less";
	
	.DbRow {
		position: relative;
		
		[showBorders2] & {
			background-color: fade( red, 20 );
			outline: 1px solid fade( red, 25 ); 
		}
		
		&:last-child {
			margin-bottom: -2px;			
		}
		
		&__scrollToHelper { // etwas nach oben verschoben, damit der viewport nach dem scrollen nicht ganz oben klebt
			position: absolute;
			top: -10px; left: 0;
			height: 1px; width: 100%;
			pointer-events: none;
			//background-color: fade( yellow, 20 );
		}
		&__header {
			position: relative;
			justify-content: space-between;
			align-items: center;
			
			br { display: none; }
		}
		&__body {
			//background-color: fade( red, 20 );
			
			position: relative;
		}
			
		&__toggleIcon {
			transition: transform 0.3s ease;
			display: block;
			width: 0.6em;
			height: 0.5em;
			background-color: currentColor;
			margin: 0 0.5em;
			margin-top: 0.35rem;
			opacity: 0.75;
		}
		&__header:hover &__toggleIcon {
			opacity: 1;
		}
		&__toggleIcon--isActive {
			transform: rotate( -180deg );
		}
		&__rowToggleBtn {
			position: absolute;
			top: 0; left: 0; width: 100%; height: 100%;
			opacity: 0;
			background-color: fade( red, 10 );
		}
	}
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
