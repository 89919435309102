<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<DbCover></DbCover>
	
	2019-06-08	init

-->

<template>
	<div class="DbCover flex flex--middle flex--center" :class="elmClasses">
		<div class="DbCover__label"></div>
		
		<div class="DbCover__bottles">
			<div class="DbCover__bottle">
				<div class="DbCover__clickTrigger" @click="clickBottle()"></div>
			</div>
			<div class="DbCover__bottle">
				<div class="DbCover__clickTrigger" @click="clickBottle()"></div>
			</div>
			<div class="DbCover__bottle">
				<div class="DbCover__clickTrigger" @click="clickBottle()"></div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'DbCover',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				showLabel : false,
			}
		},
		watch: {
			showLabel: {
				handler: function( to, from ) {
					console.log('showLabel to', to);
					if( to ){
						setTimeout( () => {
							this.showLabel = false
						}, 2000)
					}
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []
				
				if( this.showLabel ) classes.push('DbCover--showLabel')
				
				return classes
			},
		},
		methods: {
			clickBottle(){
				this.showLabel = true
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";
	
	.DbCover {
		//background-color: fade( red, 20 );
		
		position: absolute;
		top: 0; left: 0; 
		width: 100%; height: 100%;
		
		&__label {
			background-color: fade(blue, 50);
			background-color: white;
			
			transition: transform 0.2s ease-out 0s,
						opacity 0.2s ease-out 0s;
			
			position: absolute;
			top: 0; left: 0;
			height: 100%; width: 100%;
			border: 4vw solid transparent;
			z-index: 10;
			
			background-image: url(../assets/images/label--md.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			pointer-events: none;
			opacity: 0;
			transform: scale(0.1);
			
			//display: none;
		}
		&--showLabel &__label {
			opacity: 1;
			transform: scale(1);
			transition-duration: 0.1s;
		}
		
		&__clickTrigger {
			//background-color: fade( green, 50 );
			
			position: absolute;
			top: 0; height: 100%;
			left: calc( 50% - 10vh );
			width: 20vh;
			cursor: pointer;
		}
		&__bottle {
			//background-color: red;
			
			transition: transform 2s ease 0s,
						opacity 2s ease 0s;
			
			position: absolute;
			top: 18vh; left: 0%;
			//transform: translateX(-50%) translateY(-50%);
			width: 100%;
			height: 60vh;
			
			background-image: url(../assets/images/MH202989--trimed--md.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			opacity: 0.5;
		}
		&:hover {
			.DbCover__bottle:nth-child(1) {
				transition-delay: 0.35s;
				transform: translateX(2%) translateY(1px);
				opacity: 0.15;
			}
			.DbCover__bottle:nth-child(2) {
				transition-delay: 0.15s;
				transform: translateX(-2%) translateY(-1px)
			}
		}

	}
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
