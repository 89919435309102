<!--

	Diese Componente kümmert sich darum, dass interne Links aus 
	HTML-Richtext zum Router delegiert werden.
	Am besten in App.vue integrieren.
	
	Taken from https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
	and improved
	
	Just add to App.vue
		<MhDelegateLinks></MhDelegateLinks>
	
	2019-06-06	init

-->

<template>
	<div class="MhDelegateLinks"></div>
</template>

<script>
	// @ is an alias to /src
	import { EventBus } from '@/event-bus.js'
	import appConfig from '@/../appConfig.js'

	export default {
		name: 'MhDelegateLinks',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {
				debugLog : false,
			}
		},
		watch: {},
		computed: {
			internalHosts(){
				return this.getInternalHosts()
			},
			currentHost(){
				return window.location.hostname
			},
		},
		methods: {
			getInternalHosts(){	// taken from appConfig
				let internalHosts = []
				
				this._.forEach( appConfig.environments, (environment) => {
					internalHosts.push( environment.hostname )
				})
				
				return internalHosts
			},
			isURL( str ) {
		        var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		        var url = new RegExp(urlRegex, 'i');
		        return str.length < 2083 && url.test(str);
		    },
			is404( url ) { // resolve route. will it lead to NotFoundView?
				let resolved = this.$router.resolve( url )
				
				if(resolved.route.name == 'NotFoundView'){
					return true
				}else{
					return false
				}
			},
			isDownloadURL( url ) { // checks if url goes to /wp-content/uploads/
				let is = false
				
				// just exclude every link that goes to wp upload dir
				if( url && url.indexOf("/wp-content/uploads/") != -1 ) is = true
				
				return is
			},
			clickHandler( event ){
				// ensure we use the link, in case the click has been received by a subelement
				let { target } = event
				while (target && target.tagName !== 'A') target = target.parentNode
				
				const debugLog      = this.debugLog
				const href          = target && target.href ? target.href : false
				const hash      	= this.isURL( href ) ? new URL(href).hash : ''
				const hrefHost      = this.isURL( href ) ? new URL(href).hostname : false
				const routeTo       = this.isURL( href ) ? new URL(href).pathname + new URL(href).search + hash : false
				const isDownloadURL = this.isDownloadURL( href )
				const isInternal    = this._.includes( this.internalHosts, hrefHost )
				const is404         = this.is404( routeTo )
				
				if( !href ) return
				
				if( debugLog ){
					console.log('');
					console.group('Delegating HTML links to vue-router')
					console.log('             href:', href)
					console.log('             hash:', hash)
					console.log('       isInternal:', isInternal)
					console.log('            is404:', is404)
					console.log('          routeTo:', routeTo)
					//console.log('    isDownloadURL:', isDownloadURL)
				}
				
				// handle only internal links that have a valid route 
				if (target && isInternal && !is404) {
					
					// some sanity checks taken from vue-router:
					// https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
					const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
					// don't handle with control keys
					if (metaKey || altKey || ctrlKey || shiftKey) return
					// don't handle when preventDefault called
					if (defaultPrevented) return
					// don't handle right clicks
					if (button !== undefined && button !== 0) return
					// don't handle if `target="_blank"`
					if (target && target.getAttribute) {
						const linkTarget = target.getAttribute('target')
						if (/\b_blank\b/i.test(linkTarget)) return
					}
					// don't handle same page links/anchors
					const url = new URL(target.href)
					const to = routeTo //url.pathname
					if (window.location.pathname !== to && event.preventDefault) {
						if( debugLog ) console.log('	           >>>', to)
						
						event.preventDefault()
						this.$router.push(to)
					}
				}else{
					if( debugLog ) console.log('	           >>> no route to push')
				}
				
				if( debugLog ) console.groupEnd()
			},
		},
		created() {
			const internalHosts = this.getInternalHosts()
			
			window.addEventListener('click', this.clickHandler)
			
			/*
			window.addEventListener('click', event => {
				
				// ensure we use the link, in case the click has been received by a subelement
				let { target } = event
				while (target && target.tagName !== 'A') target = target.parentNode
				
				const href          = target ? target.href                        : false
				const hrefHost      = this.isURL( href ) ? new URL(href).hostname : false
				const isDownloadURL = this.isDownloadURL( href )
				const isInternal    = this._.includes(internalHosts, hrefHost)
				
				console.log('')
				console.log('Delegating HTML links to vue-router')
				console.log('             href:', href)
				console.log('         hrefHost:', hrefHost)
				console.log('       isInternal:', isInternal)
				console.log('    isDownloadURL:', isDownloadURL)
				console.log('  $router.push.to:', isDownloadURL)
				
				// handle only internal links that are no download links
				if (target && isInternal && !isDownloadURL) {
					
					console.log('	         •••••')
					
					// some sanity checks taken from vue-router:
					// https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
					const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
					// don't handle with control keys
					if (metaKey || altKey || ctrlKey || shiftKey) return
					// don't handle when preventDefault called
					if (defaultPrevented) return
					// don't handle right clicks
					if (button !== undefined && button !== 0) return
					// don't handle if `target="_blank"`
					if (target && target.getAttribute) {
						const linkTarget = target.getAttribute('target')
						if (/\b_blank\b/i.test(linkTarget)) return
					}
					// don't handle same page links/anchors
					const url = new URL(target.href)
					const to = url.pathname
					if (window.location.pathname !== to && event.preventDefault) {
						event.preventDefault()
						console.log('  $router.push.to:', to)
						//this.$router.push(to)
					}
				}
				
				event.preventDefault()
			})	
			*/			
		},
		mounted() {

		},
		beforeDestroy() {
			window.removeEventListener('click', this.clickHandler)
		},
	}
</script>

<style lang="less">
	.MhDelegateLinks {}
</style>
