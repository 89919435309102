<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<DbText
			:text="Lorem ipsum text"
		></DbText>
	
	2019-10-11	init

-->

<template>
	<div class="DbText contentModule" :class="elmClasses" v-html="text"></div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'DbText',
		components: {},
		mixins: [],
		props: {
			text: [String, Boolean],
		},
		data() {
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []
				
				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	//@import "../less/mixins.less";
	//@import (reference) "../less/atoms.less";
	
	.DbText {
		//opacity: 0.9;
		
		hr {
			border: none;
			border-top: 1px solid;
			margin: 0.5em 0;
			opacity: 0.65;
		}
		
		ul, ol, p {
			margin-bottom: 0.5em;
			
			@media @mediaQuery__dt {
				max-width: 40vw;
			}
		}
		
		ul, ol {
			//outline: 1px solid red;
			list-style-type: none;
		}
		li {
			//outline: 1px solid red;
			padding-left: 1.1em;
		}
		li:before {
			//outline: 1px solid red;
			text-indent: -0.1em;
			//font-weight: bold;
			content: '—';
			//content: '';
			width: 1.1em;
			display: inline-block;
			margin-left: -1.1em;
		}
		
		a {
			transition: opacity 0.1s ease;
			opacity: 0.9;
			color: white;
			//text-decoration: underline;
			border-bottom: 1px dotted;
			//margin-left: 1.5em;
		}
		/*
		a::before {
			//background-color: fade( red, 20 );
			
			display: inline-block;
			width: 1.5em; 
			height: 1em;
			content: " ";
			transform: translateY(15%);
			margin-left: -1.5em;
			
			background-image: url(../assets/images/sliderArrow.svg);
			background-repeat: no-repeat;
			background-position: center left;
			background-size: contain;
		}
		*/
		a:hover {
			opacity: 1;
			border-bottom-style: solid;
		}
	}
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
